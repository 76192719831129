.company-card {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.company-card-img {
  width: 100%;
  height: 100%;
  margin: auto;
}

.company-card:hover {
  opacity: 1;
  color: rgb(77, 77, 77);
  filter: none;

  transform: scale(1.2);
  font-weight: bold;
  box-shadow: 0 0 0 0 #d3d3d3;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.card-title {
}
